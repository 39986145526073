<template>
  <div>
    <v-row dense class="mb-8">
      <v-col cols="3">
        <v-autocomplete
          v-model="source"
          :items="sourceOptions"
          :label="$t('labels.source')"
          :placeholder="$t('labels.source')"
          :disabled="false"
          class="c-input-small"
          dense
          outlined
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          v-model="shop"
          :items="shopOptions"
          :label="$t('labels.shop')"
          :placeholder="$t('labels.shop')"
          :disabled="false"
          class="c-input-small"
          dense
          outlined
          hide-details
          @change="getAllowDate"
        ></v-autocomplete>
      </v-col>

      <v-col cols="6">
        <v-file-input
          accept=".xlsx"
          v-model="file"
          append-icon="mdi-paperclip"
          prepend-icon=""
          dense
          outlined
          hide-details
          class="c-input-small"
          :label="$t('labels.file')"
          :placeholder="$t('labels.file')"
        ></v-file-input>
      </v-col>

      <div class="error--text font-italic fs-14px my-3">
        * Đối tác chỉ sử dụng file tải trực tiếp & không qua chỉnh sửa từ sàn để
        xuất hóa đơn. SSC không chịu trách nhiệm về tính chính xác của hóa đơn
        khi đối tác chỉnh sửa file dữ liệu của nền tảng.
      </div>

      <v-col cols="12" v-if="!file">
        <div>
          <v-alert outlined border="left">
            <div class="mb-2 fs-14px font-weight-medium error--text">
              Hướng dẫn tải file income từ:
            </div>

            <div class="mb-3 fs-12 warning--text">
              <div class="font-weight-medium">Shopee:</div>
              <ul>
                <li>
                  1. Truy cập:
                  <a
                    href="https://banhang.shopee.vn/portal/finance/"
                    target="_blank"
                  >
                    https://banhang.shopee.vn/portal/finance/
                  </a>
                </li>
                <li>
                  2. Chọn: “Đã thanh toán” → Ấn vào khu vực thời gian : “Chọn
                  ngày”. Chọn 01 ngày duy nhất là ngày hôm qua → “Xuất” → “Tải
                  về”
                </li>
              </ul>
            </div>

            <div class="mb-2 fs-12">
              <div class="font-weight-medium">Tiktok:</div>
              <ul>
                <li>
                  1. Truy cập:
                  <a
                    href="https://seller-vn.tiktok.com/finance/transactions"
                    target="_blank"
                  >
                    https://seller-vn.tiktok.com/finance/transactions
                  </a>
                </li>
                <li>
                  2. Chọn: “Đã quyết toán” → “Xuất” → Chọn 01 ngày duy nhất là
                  ngày hôm qua → “OK” → “Xuất” → “Tải xuống”
                </li>
              </ul>
            </div>

            <div class="mb-2 fs-12 primary--text">
              <div class="font-weight-medium">Lazada:</div>
              <ul>
                <li>
                  1. Truy cập:
                  <a
                    href="https://sellercenter.lazada.vn/portal/apps/finance/myIncome/index"
                    target="_blank"
                  >
                    https://sellercenter.lazada.vn/portal/apps/finance/myIncome/index
                  </a>
                </li>
                <li>
                  2. Chọn “Đã thanh toán” → “Tải xuống” → “Chi tiết đơn hàng
                  (Excel)” → “Download Result File”.
                </li>
              </ul>
            </div>
          </v-alert>
        </div>
      </v-col>
    </v-row>

    <Shopee
      v-if="file && shop && source == 1"
      :file="file"
      :source="source"
      :id-shop="shop"
      :allow-date="allowDate"
      @success="onSuccess"
    />

    <Lazada
      v-if="file && shop && source == 2"
      :file="file"
      :source="source"
      :id-shop="shop"
      :allow-date="allowDate"
      @success="onSuccess"
    />

    <Tiktok
      v-if="file && shop && source == 5"
      :file="file"
      :source="source"
      :id-shop="shop"
      :allow-date="allowDate"
      @success="onSuccess"
    />
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { ORDER_SOURCES } from "@/libs/const";

export default {
  name: "OnlyCheck",
  components: {
    Shopee: () => import("@/components/invoice/Shopee"),
    Tiktok: () => import("@/components/invoice/Tiktok"),
    Lazada: () => import("@/components/invoice/Lazada"),
  },
  data: () => ({
    source: null,
    shop: null,
    file: null,
    allowDate: null,
    shops: [],
    sourceOptions: [...ORDER_SOURCES],
  }),
  computed: {
    shopOptions() {
      if (this.source === "none") {
        return [];
      }
      return [...this.shops]
        .filter((shop) => shop.id_e_marketplace == this.source)
        .map((shop) => ({
          text: shop.display_name,
          value: shop.id,
        }));
    },
  },
  watch: {},
  mounted() {
    this.getShops();
  },
  methods: {
    onSuccess() {
      this.file = null;
      this.getAllowDate();
      this.$emit("success", true);
    },
    getAllowDate() {
      httpClient
        .post("/invoice-get-allow-date", {
          id_customer_e_marketplace_shop: this.shop,
          source: this.source,
        })
        .then(({ data }) => {
          this.allowDate = data;
        })
        .catch();
    },
    async getShops() {
      const { data } = await httpClient.post("/list-e-market-shop", {
        active: 1,
        publish_invoice: 1,
      });
      this.shops = [...data];
    },
  },
};
</script>
